import React from "react";
import { Link } from "gatsby";
// import twitter from "../img/social/Twitter_Social_Icon_Circle_Color.svg";
import { Location } from "@reach/router";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    return (
      <nav
        className="content navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Location>
              {({ location }) => {
                if (location.pathname !== "/") {
                  return (
                    <Link to="/" className="navbar-item is-vertical-centered">
                      <h3
                        id="navbar-home-name"
                        className="has-text-primary has-no-margin-bottom"
                      >
                        Guedis Cardenas
                      </h3>
                    </Link>
                  );
                }
              }}
            </Location>

            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end has-text-centered">
              <a
                className="navbar-item"
                href="https://www.gued.is/garden"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
              <Link className="navbar-item" to="/#products">
                Products
              </Link>
              <Link className="navbar-item" to="/about">
                About
              </Link>
              <Link className="navbar-item" to="/contact">
                Contact
              </Link>
              {/* <a
                className="navbar-item"
                href="https://github.com/netlify-templates/gatsby-starter-netlify-cms"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <img src={twitter} alt="Twitter" />
                </span>
              </a> */}
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
