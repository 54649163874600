import React from "react";
// import { Link } from "gatsby";

// import logo from "../img/logo.svg";
// import facebook from "../img/social/facebook.svg";
// import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import linkedin from "../img/social/linkedin.svg";
// import vimeo from "../img/social/vimeo.svg";
import github from "../img/github-icon.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer
        id="main-footer"
        className="has-background-purple-tiles has-text-white-ter"
      >
        <div className="content has-text-centered has-background-purple-tiles has-text-primary">
          <div className="container">
            <div className="columns">
              <div className="column is-4 is-hidden-mobile" />
              <div className="column is-4">
                <div className="content has-text-centered">
                  <p>Together, We're Stronger &#10084;</p>
                </div>
              </div>
              <div className="column is-4 social">
                <a
                  title="github"
                  href="https://github.com/palante"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={github}
                    alt="Github"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a
                  title="twitter"
                  href="https://twitter.com/holaguedis"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>

                <a
                  title="linkedin"
                  href="https://linkedin.com/in/guedis"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={linkedin}
                    alt="LinkedIn"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
